import React from 'react';
import './resume.css'
import Nav from '../components/nav'
import Layout from '../components/layout';

const Resume = () => (
  <Layout>
    <Nav />
    <div className="resume__container">
      <img src={require('../images/Perkel_Resume_Actual.jpg')}/>
    </div>
  </Layout>
)

export default Resume